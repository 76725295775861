
  /* eslint-disable */
  import Vue, { PropType } from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import VuePhoneNumberInput from 'vue-phone-number-input';

  export default Vue.extend({
    name: 'es-validate-phone-modal',

    components: {
      'es-phone-number-input': VuePhoneNumberInput,
    },

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      actionType: {
        default: 'add',
        type: String as PropType<string>,
      },
      canCloseModal: {
        default: true,
        type: Boolean,
      },
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
    },

    computed: {
      ...mapGetters({ getUser: 'session/getUser' }),
    },

    data() {
      return {
        phone_number: null,
        formattedPhoneNumber: null,
        translations: {
          countrySelectorLabel: this.$t('generic.phone_nr_country_selector'),
          countrySelectorError: this.$t('generic.phone_nr_selector_error'),
          phoneNumberLabel: this.$t('generic.phone_nr_label'),
          example: this.$t('generic.phone_nr_example')
        },
      }
    },

    watch: {
      getUser(newVal) {
        if (newVal.phone_number) {
          this.$emit('is-open', false);
          this.$emit('show-phone-confirmation-modal', true);
        }
      },
    },

    methods: {
      ...mapActions({
        requestValidationCode: 'session/requestValidationCode',
      }),

      onPhoneNumberUpdate(evt: any) {
        this.formattedPhoneNumber = evt.formattedNumber;
      },

      async requestPhoneNumberValidationCode() {
        const phone = this.formattedPhoneNumber || this.getUser.phone_number;
        await this.requestValidationCode(phone);
      },
    },
  });
