
  import Vue, { PropType } from 'vue';
  import { mapGetters, mapActions } from 'vuex';

  export default Vue.extend({
    name: 'es-address-modal',

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
    },
    /* eslint-disable */
    data: () => ({
      location: null,
      city: '',
      isDifferentCity: false,
      address: {
        street_name: null,
        street_number: null,
        address_type: null,
        pets: null,
        parking: null,
        notes: null,
        floor: null,
        apartment_number: null,
        postcode: null,
        lat: null,
        lng: null,
        equipment_ids: [],
      },
    }),

    computed: {
      ...mapGetters({
        getLocation: 'address/getLocation',
        getLocationError: 'address/getLocationError',
        getLocationById: 'address/getLocationById',
        isFetching: 'address/isFetching',
        getReservationAddress: 'address/getReservationAddress',
        getUserType: 'session/getUserType',
      }),
    },

    watch: {
      getLocation(newVal) {
        if (newVal) {
          const reservationAddressId = this.getReservationAddress
            ? this.getReservationAddress.city.id
            : localStorage.getItem('city_id');
          const newAddressId = newVal.city_id;
          this.isDifferentCity = reservationAddressId != newAddressId;
        }
      },

      getLocationError(newVal) {
        (this as any).$toasts.toast({
          intent: 'error',
          id: 'address-toast',
          title: this.$t('toast.warning_title'),
          message: this.$t('toast.address_outside_of_area'),
        });
      },

      getLocationById(newVal, oldVal) {
        this.fetchAddresses();
        this.$emit('is-open', false);
      },
    },

    mounted() {
      (window as any).checkAndAttachMapScript(this.initLocationSearch);
    },

    methods: {
      ...mapActions({
        fetchLocation: 'address/fetchLocation',
        fetchAddresses: 'address/fetchAddresses',
        setAddress: 'address/setAddress',
        getUser: 'session/getUser',
      }),

      async initLocationSearch() {
        await this.$nextTick();
        const autocomplete = new (window as any).google.maps.places.Autocomplete(this.$refs.search);
        autocomplete.setFields(['geometry.location','address_component','formatted_address', 'name']);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          this.address.street_number = place.address_components
            .find((item: any) => item.types[0] === 'street_number')?.short_name;
          this.address.street_name = place.address_components
            .find((item: any) => item.types[0] === 'route')?.short_name;
          this.city = place.address_components
            .find((item: any) => item.types[0] === 'locality')?.short_name;
          this.address.lat = place.geometry.location.lat();
          this.address.lng = place.geometry.location.lng();
          this.location = place.formatted_address;
          this.fetchLocation(place);
        });
      },

      async onAddAddress(): Promise<void> {
        const { name } = this.$router.currentRoute;
        const { address, city } = this;
        const { street_number, street_name } = address;

        if (name === 'Plata rezervare' && this.isDifferentCity) {
          (this as any).$toasts.toast({
            id: 'address-modal',
            title: this.$t('toast.warning_title'),
            intent: 'warning',
            message: this.$t('toast.city_is_changed'),
          });
          return;
        }

        if (street_name && street_number && city) {
          await this.setAddress(this.address);
          await this.getUser();
        } else {
          (this as any).$toasts.toast({
            id: 'address-modal',
            title: this.$t('toast.warning_title'),
            intent: 'warning',
            message: this.$t('toast.address_is_incomplete'),
          });
        }
      },
    },
  });
