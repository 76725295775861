
  /* eslint-disable */
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'es-reset-password-modal',

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
    },

    data: () => ({
      password: null,
      type: 'password',
    }),

    methods: {
      ...mapActions({
        resetPassword: 'resetPassword',
      }),
      onSubmit() {
        console.log(this.password);
      },
    },
  });
