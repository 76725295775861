
  import Vue, { PropType } from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import { Address } from '@/interfaces/Address';

  export default Vue.extend({
    name: 'es-address-modal-complete',

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
      selectedAddress: {
        required: true,
        type: Object as PropType<Address>,
      },
    },
    /* eslint-disable */
    data() {
      return {
        typeOptions: [
          { value: 'private_residence', label: this.$t('address.type.private') },
          { value: 'office', label: this.$t('address.type.office') },
          { value: 'hotel', label: this.$t('address.type.hotel') },
        ],
        petOptions: [
          { value: 'cat', label: this.$t('address.pet.cat') },
          { value: 'dog_and_cat', label: this.$t('address.pet.dogAndCat') },
          { value: 'dog', label: this.$t('address.pet.dog')},
        ],
        parkingOptions: [
          { value: 'free_on_street', label: this.$t('address.parking.free_street') },
          { value: 'paid_on_street', label: this.$t('address.parking.paid_street') },
          { value: 'private_park', label: this.$t('address.parking.private') },
        ],
        city: '',
        address: {
          street_name: '',
          street_number: '',
          address_type: '',
          pets: '',
          parking: '',
          notes: '',
          floor: '',
          apartment_number: '',
          postcode: '',
          lat: '',
          lng: '',
          equipment_ids: [] as number[],
        },
      };
    },

    computed: {
      ...mapGetters({
        isFetching: 'address/isFetching',
      }),
    },

    created() {
      const {
        address_type,
        apartment_number,
        city,
        equipment,
        floor,
        lat,
        lng,
        notes,
        parking,
        pets,
        street_name,
        street_number,
      } = this.selectedAddress;

      const equipmentId = equipment[0]?.id;

      this.address.street_number = street_number;
      this.address.street_name = street_name;
      this.address.floor = floor;
      this.address.apartment_number = apartment_number;
      this.address.address_type = address_type;
      this.address.pets = pets;
      this.address.parking = parking;
      this.address.notes = notes;
      this.address.lat = lat;
      this.address.lng = lng;
      this.city = city.name;

      if (equipmentId) {
        this.address.equipment_ids.push(equipmentId);
      }
    },

    methods: {
      ...mapActions({
        updateAddress: 'address/updateAddress',
      }),

      onEquipmentChange(includes: boolean) {
        if (includes) {
          this.address.equipment_ids = [];
        } else {
          this.address.equipment_ids.push(1);
        }
      },

      async saveAddress(): Promise<void> {
        const { address, selectedAddress } = this;
        const { id } = selectedAddress;
        await this.updateAddress({ address, id });
        this.$emit('is-open', false);
      },
    },
  });
