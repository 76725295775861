
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import { LoginModal } from '@/components/login-modal';
  import { ValidatePhoneModal } from '@/components/shared/validate-phone-modal';
  import { PhoneConfirmationModal } from '@/components/shared/phone-confirmation-modal';
  import { ForgotPasswordModal } from '@/components/shared/forgot-password-modal';
  import { ResetPasswordModal } from '@/components/shared/reset-password-modal';
  import { AddressModal } from '@/components/shared/address-modal';
  import { NAVBAR_LINKS, PRO_NAVBAR_LINKS } from '@/constants/navbar-links';

  export default Vue.extend({
    name: 'es-header',

    components: {
      'es-address-modal': AddressModal,
      'es-login-modal': LoginModal,
      'es-validate-phone-modal': ValidatePhoneModal,
      'es-phone-confirmation-modal': PhoneConfirmationModal,
      'es-forgot-password-modal': ForgotPasswordModal,
      'es-reset-password-modal': ResetPasswordModal,
    },

    data: () => ({
      isPro: false,
      isAddressModalOpen: false,
      isHomePage: false,
      isLoginModalOpen: false,
      isPhoneConfirmationModalOpen: false,
      isValidatePhoneModalOpen: false,
      isForgotPasswordModalOpen: false,
      isResetPasswordModalOpen: false,
      modalType: 'login',
      userType: 'client',
      modalWasShown: false,
    }),

    computed: {
      ...mapGetters({
        isAuthenticated: 'session/isAuthenticated',
        getUserType: 'session/getUserType',
        getUser: 'session/getUser',
      }),
      getInitials(): string {
        const firstNameI = this.getUser.first_name?.split(' ').map((n: any) => n[0]).join('');
        const lastNameI = this.getUser.last_name?.split(' ').map((n: any) => n[0]).join('');
        return `${firstNameI.toUpperCase()}${lastNameI.toUpperCase()}`;
      },
      getNavbarLinks(): any {
        return this.getUserType === 'elite' ? PRO_NAVBAR_LINKS : NAVBAR_LINKS;
      },
      getAuthNavLinks(): any {
        return this.getNavbarLinks
          .filter((item: any) => item.requiresAuth)
          .map((item: any) => ({ ...item, id: nanoid() }));
      },
      navLinks(): any {
        return this.getNavbarLinks
          .filter((item: any) => !item.requiresAuth)
          .map((item: any) => ({ ...item, id: nanoid() }));
      },
    },

    watch: {
      isLoginModalOpen(newVal) {
        if (!newVal && !this.isForgotPasswordModalOpen) {
          this.userType = 'client';
        }
      },
      $route(to) {
        this.isPro = this.$router.currentRoute.path.includes('easepro');
        this.isHomePage = to.name === 'Home' || to.name === 'ProHome';
      },
      getUser(newVal) {
        const { currentRoute } = this.$router;
        if (this.isAuthenticated && !newVal?.phone_number_confirmed && !currentRoute.path.includes('telefon') && !this.isPhoneConfirmationModalOpen) {
          // this.isValidatePhoneModalOpen = true;
        }
      },
    },

    created() {
      this.isPro = this.$router.currentRoute.path.includes('pro');
    },

    mounted() {
      (window as any).initEase();
      this.$root.$on('on-show-login', () => {
        this.isLoginModalOpen = true;
        (this as any).$toasts.toast({
          id: 'login-toast',
          intent: 'info',
          title: this.$t('toast.info_title'),
          message: this.$t('toast.login_or_register'),
        });
      });
      this.$root.$on('on-show-elite-register', () => {
        this.modalType = 'register';
        this.userType = 'elite';
        this.isLoginModalOpen = true;
      });
      this.$root.$on('on-show-address-modal', () => {
        this.isAddressModalOpen = true;
      });
      this.$root.$on('on-show-validate-phone-modal', () => {
        this.isValidatePhoneModalOpen = true;
      });
    },

    updated() {
      const { query } = this.$router.currentRoute;

      if (query.referral_code && !this.modalWasShown) {
        this.modalWasShown = true;
        this.modalType = 'register';
        this.isLoginModalOpen = true;
      }
    },

    methods: {
      ...mapActions({
        logout: 'session/logout',
      }),
      onNavLinkClick(to: string): void {
        this.$router.push(to);
      },

      onTypeChange(type: string): void {
        this.userType = type;
      },

      async onLogout(): Promise<void> {
        await this.logout();
        this.$router.push({ name: this.isPro ? 'ProHome' : 'Home' });
        if (this.isValidatePhoneModalOpen) {
          this.isValidatePhoneModalOpen = false;
        }
      },
    },
  });
