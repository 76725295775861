
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { AuthModal } from '@/components/shared/auth-modal';
  import { EsHeader } from '@/components/header';
  import { EsFooter } from '@/components/footer';

  export default Vue.extend({
    name: 'ease-app',

    components: {
      'es-header': EsHeader,
      'es-footer': EsFooter,
      'es-auth-modal': AuthModal,
    },

    data: () => ({
      isModalOpen: false,
      username: '',
      password: '',
      isMounted: false,
    }),

    computed: {
      ...mapGetters({
        isAuth: 'session/isAuth',
      }),
    },

    watch: {
      isAuth(newVal, oldVal) {
        if (newVal && !oldVal) {
          this.isModalOpen = false;
        }
      },
    },

    mounted() {
      this.isMounted = true;
    },
  });
