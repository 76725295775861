
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'auth-modal',

    data: () => ({
      password: null,
    }),

    methods: {
      ...mapActions({
        setToken: 'session/setToken',
      }),

      authenticate() {
        this.setToken(this.password);
      },
    }, // methods
  });
