
  /* eslint-disable */
  import Vue, { PropType } from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import { startCase } from 'lodash-es';

  export default Vue.extend({
    name: 'es-login-modal',

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
      modalType: {
        default: 'login',
        type: String as PropType<string>,
      },
      type: {
        default: 'client',
        type: String as PropType<string>,
      },
    },

    data: () => ({
      userType: '',
      showPassword: false,
      passwordType: 'password',
      form: {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        phone_number: null,
        massage_marketing: true,
        beauty_marketing: true,
        fitness_marketing: true,
        gender: 'female',
        subscribe_to_marketing_emails_list: false,
      },
      terms_and_conditions: false,
      isSignIn: true,
    }),

    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
        isFetchingUser: 'session/isFetchingUser',
      }),
      loadingStyle() {
        const lang = localStorage.getItem('lang');

        return ({
          left: lang === 'en' ? '32%' : '24%',
         });
      },
    },

    watch: {
      getUser(newVal) {
        this.$emit('is-open', false);
        if (!newVal.phone_number_confirmed) {
          this.$emit('show-validate-phone-modal', true);
        }
      },
      userType(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('on-type-change', newVal);
        }
      },
    },

    created() {
      this.userType = this.$router.currentRoute.path.includes('easepro') ? 'elite' : 'client';
      this.isSignIn = this.modalType !== 'register';
      this.userType = this.type;
    },

    methods: {
      ...mapActions({
        login: 'session/login',
        signUp: 'session/signUp',
      }),

      async onSubmit() {
        if (!this.terms_and_conditions && !this.isSignIn) {
          (this as any).$toasts.toast({
            id: 'warning-toast',
            intent: 'warning',
            message: this.$t('toast.accept_terms_and_conditions'),
            title: this.$t('toast.warning_title'),
          });

          return;
        }

        const { name } = this.$router.currentRoute;
        const { form, userType } = this;

        if (this.isSignIn) {
          await this.login({
            credentials: form,
            type: userType,
          });
        } else {
          const formData = {
            ...form,
            first_name: startCase(form.first_name.toLowerCase()),
            last_name: startCase(form.last_name.toLowerCase()),
          }

          await this.signUp({
            credentials: formData,
            type: userType,
          });
          if (name === 'Home' && userType !== 'elite') {
            this.$router.push('/servicii');
          }
        }
      },

      onForgotPassword() {
        this.$emit('is-open', false);
        this.$emit('show-forgot-password-modal', true);
      },
    },
  });
