
  /* eslint-disable */
  import Vue, { PropType } from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import { nanoid } from 'nanoid';

  export default Vue.extend({
    name: 'es-phone-confirmation-modal',

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      actionType: {
        default: 'add',
        type: String as PropType<string>,
      },
      canCloseModal: {
        default: true,
        type: Boolean,
      },
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
    },

    data: () => ({
      validationCode: null,
    }),

    computed: {
      ...mapGetters({ getUser: 'session/getUser' }),
    },

    watch: {
      getUser(newVal, oldVal) {
        if (newVal.phone_number_confirmed) {
          (this as any).$toasts.toasts.push({
            id: nanoid(),
            intent: 'success',
            title: this.$t('toast.congrats_title'),
            message: this.$t('toast.phone_number_confirmed'),
          });
          this.$emit('is-open', false);
        }
      },
    },

    methods: {
      ...mapActions({
        requestValidationCode: 'session/requestValidationCode',
        validatePhoneNumber: 'session/validatePhoneNumber',
      }),

      async requestPhoneNumberValidationCode() {
        await this.requestValidationCode(this.getUser.phone_number);
      },

      async requestValidatePhoneNumber() {
        await this.validatePhoneNumber(this.validationCode);
      },
    },
  });
