
  import Vue from 'vue';
  import { mapGetters, mapActions } from 'vuex';

  export default Vue.extend({
    name: 'es-address-search',

    props: {
      homePage: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      location: null,
    }),

    computed: {
      ...mapGetters({
        getLocation: 'address/getLocation',
        getLocationError: 'address/getLocationError',
      }),
      showAlert(): boolean {
        const { location, homePage } = this;
        const cityId = localStorage.getItem('city_id');
        const addressFromStorage = cityId === 'null' ? null : cityId;

        const savedLocation = location || addressFromStorage;
        return !savedLocation && !homePage;
      },
    },

    watch: {
      getLocation(newVal) {
        if (!newVal) {
          this.location = null;
        }
      },
    },

    mounted() {
      (window as any).checkAndAttachMapScript(this.initLocationSearch);
    },

    created() {
      const savedLocation = localStorage.getItem('address') === 'null'
        ? null
        : localStorage.getItem('address');

      this.location = this.getLocation ? this.getLocation.formatted_address : savedLocation;
    },

    methods: {
      ...mapActions({
        fetchLocation: 'address/fetchLocation',
        fetchServices: 'services/fetchServices',
      }),
      initLocationSearch(): void {
        const autocomplete = new (window as any).google.maps.places.Autocomplete(this.$refs.search);
        autocomplete.setFields(['geometry.location', 'formatted_address', 'name', 'address_component']);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          this.fetchLocation(place);
          this.$emit('on-address-change', place);
          this.location = place.formatted_address;
        });
      },
    },
  });
