export const acceptedLinksWithIdForClientWave = (routeParamId: string, proId: string) => ([
  `/servicii/single/${routeParamId}/rezerva`,
  `/servicii/single/${routeParamId}/rezerva/plata`,
  `/servicii/couple/${routeParamId}/rezerva`,
  `/servicii/couple/${routeParamId}/rezerva/plata`,
  `/servicii/nails/${routeParamId}`,
  `/servicii/nails/${routeParamId}/rezerva`,
  `/servicii/nails/${routeParamId}/rezerva/plata`,
  `/servicii/nails/${routeParamId}?pro_id=${proId}`,
  `/servicii/eyelashes/${routeParamId}`,
  `/servicii/eyelashes/${routeParamId}/rezerva`,
  `/servicii/eyelashes/${routeParamId}/rezerva/plata`,
  `/servicii/eyebrows/${routeParamId}`,
  `/servicii/eyebrows/${routeParamId}/rezerva`,
  `/servicii/eyebrows/${routeParamId}/rezerva/plata`,
  `/carduri-cadou/${routeParamId}`,
  `/carduri-cadou/${routeParamId}/plata`,
  `/servicii/men_hair_removal/${routeParamId}`,
  `/servicii/men_hair_removal/${routeParamId}/rezerva`,
  `/servicii/men_hair_removal/${routeParamId}/rezerva/plata`,
  `/servicii/men_nails/${routeParamId}`,
  `/servicii/men_nails/${routeParamId}/rezerva`,
  `/servicii/men_nails/${routeParamId}/rezerva/plata`,
  `/servicii/men_hair/${routeParamId}`,
  `/servicii/men_hair/${routeParamId}/rezerva`,
  `/servicii/men_hair/${routeParamId}/rezerva/plata`,
  `/servicii/fitness/${routeParamId}`,
  `/servicii/fitness/${routeParamId}/rezerva`,
  `/servicii/fitness/${routeParamId}/rezerva/plata`,
  `/pro/${routeParamId}`,
  `/user/reset-password?token=${routeParamId}`,
  `/servicii/hair/${routeParamId}`,
  `/servicii/hair/${routeParamId}?pro_id=${proId}`,
  `/servicii/masaj?type=single&pro_id=${proId}`,
  `/servicii/masaj?type=couple&pro_id=${proId}`,
  `/new/servicii/masaj?type=couple&pro_id=${proId}`,
  `/servicii/fitness/${routeParamId}?pro_id=${proId}`,
]);

export const acceptedLinksForClientWave = [
  'pro/',
  '/servicii/masaj?type=single',
  '/servicii/masaj?type=couple',
  '/new/servicii/masaj?type=couple',
  '/abonamente/massage?tip=monthly',
  '/abonamente/rezerva',
  '/client/cont',
  '/client/parola',
  '/client/telefon',
  '/client/recomandare',
  '/client/adrese',
  '/client/metode-de-plata',
  '/client/rezervarile-mele',
  '/client/comenzile-mele',
  '/client/profesionisti-salvati',
  '/client/abonamente',
  '/client/credit-ease',
  '/client/preferinte',
  '/contact',
  '/politica-de-confidentialitate',
  '/termeni-si-conditii',
  '/termeni-si-conditii-carduri-cadou-ease',
  '/termeni-si-conditii-program-de-recomandare-ease',
];
