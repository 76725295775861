
  /* eslint-disable */
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';
  import { validateEmail } from '@/utils/validate-email';

  export default Vue.extend({
    name: 'es-forgot-password-modal',

    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model

    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
      type: {
        default: 'client',
        type: String,
      },
    },

    data: () => ({
      email: null,
    }),

    methods: {
      ...mapActions({
        forgotPassword: 'session/forgotPassword',
      }),
      async onSubmit() {
        const { email, type } = this;
        if (validateEmail(email)) {
          await this.forgotPassword({ email, type });
          (this as any).$toasts.toast({
            id: 'forgot-password',
            title: this.$t('toast.warning_title'),
            message: this.$t('toast.password_reset'),
            intent: 'warning',
          });
          this.$emit('is-open', false);
        } else {
          (this as any).$toasts.toast({
            id: 'forgot-password',
            title: this.$t('toast.warning_title'),
            message: this.$t('toast.invalid_email'),
            intent: 'warning',
          });
        }
      },
    },
  });
